import { SchemaWithId } from '@soniq/schema'
import { EntityKeys } from '../types.ts'
import { Optional } from '@pogokid/util'
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import {
  AsyncSingleResponse,
  ResponseWithRefetch,
  SingleResponse,
} from '@soniq/public-resource'
import { findEntityInitialData } from '../entityInvalidation.ts'

export function useEntityById<
  Schema extends SchemaWithId<unknown>,
  Keys extends EntityKeys<Record<string, never>>
>(
  id: Optional<string>,
  queryKey: QueryKey,
  entityKeys: Keys,
  options: {
    idProp?: keyof Schema
  } & Omit<
    UseQueryOptions<
      SingleResponse<Schema> | null,
      Error,
      SingleResponse<Schema>
    >,
    'queryKey'
  >
): AsyncSingleResponse<Schema> & ResponseWithRefetch {
  const queryClient = useQueryClient()
  const { idProp, ...queryOptions } = options
  const { data, isLoading, error, refetch } = useQuery({
    ...queryOptions,
    enabled: options.enabled !== false && !!id,
    queryKey: queryKey,
    ...findEntityInitialData<Schema, Keys>({
      queryClient,
      entityKeys,
      id,
      idProp,
    }),
  })
  return {
    value: data?.data,
    loading: isLoading,
    error: error as Error | null | undefined,
    refetch,
  }
}

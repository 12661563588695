import { Optional } from '@pogokid/util'
import { buildEntityKeys } from '@soniq/public-manager'

export const contactKeys = {
  ...buildEntityKeys<{ byIds: string | null }>('contact'),
  byUserId: (userId: Optional<string>) =>
    [...contactKeys.lists(), `byUser=${userId}`] as const,
}
export const contactRelationshipKeys = buildEntityKeys<{
  byStudent?: string | null
  byContact?: string | null
}>('contact_relationship')

export const profileKeys = {
  ...buildEntityKeys('profile'),
  handle: (handle: Optional<string>) => [
    ...profileKeys.all,
    'handle',
    handle,
  ],
}

export const inviteKeys = buildEntityKeys('invite')
export const locationKeys = buildEntityKeys('location')
export const profileSettingsKeys = buildEntityKeys(
  'profile_settings'
)

export const taggedEntityKeys = buildEntityKeys<{}>(
  'tagged_entity'
)

/**
 * TODO: DT 2024-02-25 remove studentKeys
 * @deprecated
 */
export const studentKeys = {
  all: ['student'] as const,
  lists: () => [...studentKeys.all, 'list'] as const,
  list: (filters: Optional<string>) =>
    [...studentKeys.lists(), filters] as const,
  details: () => [...studentKeys.all, 'detail'] as const,
  detail: (id: Optional<string>) =>
    [...studentKeys.details(), id] as const,
}

import { Optional } from '@pogokid/util'
import { IntervalISOString } from '@pogokid/util/datetime'
import { buildEntityKeys } from '@soniq/public-manager'

export const scheduleKeys = buildEntityKeys<{
  studentId: Optional<string>
  start: Optional<string>
  studentUnavailable: boolean
}>('schedule')

export const scheduleInstanceKeys = {
  ...buildEntityKeys<{
    scheduleId: Optional<string>
    contactId: Optional<string>
  }>('schedule_instance'),
  byStudentAndInterval: (
    studentId: Optional<string>,
    interval: IntervalISOString
  ) =>
    [
      ...scheduleInstanceKeys.lists(),
      'by-student-and-interval',
      {
        studentId,
        start: interval.start,
        end: interval.end,
      },
    ] as const,
}

import { SupabaseClient } from '@pogokid/supabase'
import { setEntityMutations } from '@soniq/public-manager'
import {
  scheduleInstanceStore,
  scheduleStore,
} from '@soniq/public-resource-scheduling'

import {
  ScheduleInstanceSchema,
  ScheduleSchema,
} from '@soniq/schema-scheduling'
import { QueryClient } from '@tanstack/react-query'
import { scheduleInstanceKeys, scheduleKeys } from './keys'

export function setSchedulingManagerEntityMutations(
  queryClient: QueryClient,
  db: SupabaseClient
) {
  // Schedule
  setEntityMutations<ScheduleSchema, typeof scheduleKeys>({
    queryClient,
    entityKeys: scheduleKeys,
    upsertEntities: async ({ data }) => {
      const res = await scheduleStore.upsert(db, data)
      await queryClient.invalidateQueries({
        queryKey: scheduleKeys.lists(),
      })
      return res
    },
    updateEntity: async ({ data }) =>
      scheduleStore.update(db, data),
    deleteEntity: async ({ id }) => {
      if (id) {
        await scheduleStore.delete(db, [id])
        await queryClient.invalidateQueries({
          queryKey: scheduleKeys.lists(),
        })
      } else {
        throw new Error('Cannot delete schedule with no id')
      }
    },
  })
  // Schedule Instance
  setEntityMutations<
    ScheduleInstanceSchema,
    typeof scheduleInstanceKeys
  >({
    queryClient,
    entityKeys: scheduleInstanceKeys,
    upsertEntities: async ({ data }) => {
      return scheduleInstanceStore.upsert(db, data)
    },
    updateEntity: async ({ data }) => {
      return scheduleInstanceStore.update(db, data)
    },
    deleteEntity: async (id) => {
      throw new Error(`Delete Not implemented: ${id}`)
    },
  })
}

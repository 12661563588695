import { SupabaseClient } from '@pogokid/supabase'
import { SupabaseReadWriteDeleteStore } from '@soniq/public-resource/supabase'
import {
  ScheduleInstanceSchema,
  validateScheduleInstance,
} from '@soniq/schema-scheduling'
import { Database } from '@soniq/util-supabase'

export class ScheduleInstanceStore extends SupabaseReadWriteDeleteStore<
  'schedule_instance',
  ScheduleInstanceSchema
> {
  static new() {
    return new ScheduleInstanceStore(
      'schedule_instance',
      validateScheduleInstance
    )
  }

  async fetchByScheduleId(
    db: SupabaseClient,
    scheduleId: string
  ) {
    return this.runQuery(
      db,
      async (table) =>
        await table.select().match({ schedule_id: scheduleId })
    )
  }
}

export const scheduleInstanceCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('schedule_instance')

import { newId, SupabaseClient } from '@pogokid/supabase'
import {
  QueryResponse,
  SingleResponse,
} from '@soniq/public-resource'
import {
  insertInto,
  SupabaseReadWriteStore,
  updateOne,
  upsertManyInto,
} from '@soniq/public-resource/supabase'
import {
  ensureCreatedFields,
  OmitCreated,
  SchemaWithId,
} from '@soniq/schema'
import {
  ContactRelationshipWithId,
  ContactSchema,
  ContactWithId,
  validateContact,
} from '@soniq/schema-connection'
import { Database } from '@soniq/util-supabase'
import { setContactRelationships } from '../contactRelationship'

export class ContactStore extends SupabaseReadWriteStore<
  'contact',
  ContactSchema
> {
  static new() {
    return new ContactStore('contact', validateContact)
  }
}

export const contactCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('contact')

export async function createContact(
  db: SupabaseClient,
  userId: string,
  contact: OmitCreated<ContactSchema>
): Promise<SingleResponse<SchemaWithId<ContactWithId>>> {
  return insertInto<ContactSchema>(contactCollection(db), {
    ...contact,
    owner_id: userId,
    created_by: userId,
    created_at: new Date().toISOString(),
  })
}

export async function setContacts(
  db: SupabaseClient,
  contacts: ContactWithId[]
): Promise<QueryResponse<ContactWithId>> {
  const invalidContact = contacts.find(
    ({ id, ...contact }) => !validateContact(contact).isValid
  )
  if (invalidContact) {
    throw new Error(`Cannot set contacts, invalid data`)
  }
  return upsertManyInto<ContactSchema>(
    contactCollection(db),
    contacts
  )
}

export async function updateContact(
  db: SupabaseClient,
  id: string,
  contact: Partial<ContactSchema>
) {
  return updateOne<ContactWithId>(
    contactCollection(db),
    id,
    contact
  )
}

/**
 * Create a new contact that is a student
 * including the associated user contact record
 * and any linked contacts
 */
export async function createStudentWithContacts(
  db: SupabaseClient,
  userId: string,
  { id, ...student }: ContactWithId,
  relationships?: ContactRelationshipWithId[],
  contacts?: ContactWithId[]
): Promise<SingleResponse<ContactWithId>> {
  const { isValid, cleanedValues } = validateContact({
    ...student,
    is_student: true,
    meta: {},
  })
  if (isValid) {
    const resolvedId = id ?? newId()
    const newStudent: ContactWithId = {
      ...cleanedValues,
      id: resolvedId,
    }
    const relationshipToStudent =
      ensureCreatedFields<ContactRelationshipWithId>(userId, {
        id: newId(),
        subject_id: userId,
        contact_id: resolvedId,
        relationship: 'student',
      })
    await setContacts(db, [newStudent, ...(contacts ?? [])])
    await setContactRelationships(db, [
      relationshipToStudent,
      ...(relationships ?? []).map(
        ({ subject_id, ...relationship }) => ({
          ...relationship,
          subject_id: resolvedId,
        })
      ),
    ])
    return { data: newStudent }
  } else {
    throw new Error('Cannot create student, invalid data')
  }
}

export async function upsertContactsAndRelationships(
  db: SupabaseClient,
  contacts?: ContactWithId[],
  relationships?: ContactRelationshipWithId[]
): Promise<SingleResponse<ContactRelationshipWithId[]>> {
  if (contacts?.length) {
    await setContacts(db, contacts ?? [])
  }
  if (relationships?.length) {
    const { data } = await setContactRelationships(
      db,
      relationships
    )
    return { data }
  } else {
    return { data: [] }
  }
}

import {
  useEnsureUserId,
  useSupabaseClient,
} from '@pogokid/supabase/react'
import { isArrayWithLength, Optional } from '@pogokid/util'
import {
  buildEntityHooks,
  useEntityMutations,
} from '@soniq/public-manager'
import {
  AsyncQueryResponse,
  AsyncSingleResponse,
  ResponseWithRefetch,
} from '@soniq/public-resource'
import {
  ContactStore,
  contactStore,
} from '@soniq/public-resource-connection'
import { SchemaWithId } from '@soniq/schema'
import {
  ContactSchema,
  ContactWithId,
} from '@soniq/schema-connection'
import { TaggedEntitySchema } from '@soniq/schema-util'
import { useCallback } from 'react'
import { contactKeys, taggedEntityKeys } from '../keys'

export const contactHooks = buildEntityHooks<
  ContactSchema,
  typeof contactKeys,
  ContactStore
>(contactKeys, contactStore)

export function useContacts(): AsyncQueryResponse<ContactWithId> {
  const db = useSupabaseClient()
  return contactHooks.useByQuery({ byIds: null }, (store) =>
    store.fetchQuery(db)
  )
}

export function useContactById(id: Optional<string>) {
  return contactHooks.useById(id)
}

export function useContactsByIds(
  ids: Optional<string[]>
): AsyncSingleResponse<ContactWithId[]> & ResponseWithRefetch {
  const db = useSupabaseClient()
  const enabled = isArrayWithLength(ids)
  return contactHooks.useByQuery(
    { byIds: ids?.toSorted().join() ?? null },
    (store) => store.fetchByIds(db, ids!),
    { enabled }
  )
}

export function useMutateContact() {
  const userId = useEnsureUserId()
  const { update } = useEntityMutations<
    ContactSchema,
    typeof contactKeys
  >({
    entityKeys: contactKeys,
    userId,
  })
  return {
    updateContact: useCallback(
      async function updateContact(
        contact: SchemaWithId<Partial<ContactSchema>>
      ) {
        const { data } = await update(contact)
        return data
      },
      [update]
    ),
  }
}

export function useMutateTaggedEntity() {
  const userId = useEnsureUserId()
  return useEntityMutations<
    TaggedEntitySchema,
    typeof taggedEntityKeys
  >({
    entityKeys: taggedEntityKeys,
    userId,
  })
}

import { SupabaseClient } from '@pogokid/supabase'
import { ISODateTimeString } from '@pogokid/util/datetime'
import { SupabaseReadWriteDeleteStore } from '@soniq/public-resource/supabase'
import {
  ScheduleSchema,
  ScheduleWithId,
  validateSchedule,
} from '@soniq/schema-scheduling'
import { Database } from '@soniq/util-supabase'
import { VISIBLE_SCHEDULE_TYPES } from './types'

export class ScheduleStore extends SupabaseReadWriteDeleteStore<
  'schedule',
  ScheduleSchema
> {
  static new() {
    return new ScheduleStore('schedule', validateSchedule)
  }

  /**
   * All schedules which are visible on the
   * calendar by default.
   *
   * Schedules like StudentUnavailable are not in
   * this because they are only shown in specific
   * circumstances
   */
  async fetchVisibleSchedulesEndAfter(
    db: SupabaseClient,
    userId: string,
    after: ISODateTimeString
  ) {
    return this.runQuery(
      db,
      async (table) =>
        await table
          .select<'*', ScheduleWithId>()
          .is('deleted_at', null)
          .eq('created_by', userId)
          .gte('event_end', after)
          .in('type', VISIBLE_SCHEDULE_TYPES)
    )
  }
}

export const scheduleCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('schedule')

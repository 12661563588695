import { WeekDays } from '@pogokid/util/datetime'
import { groupBy, pick } from '@pogokid/util/lodash'
import { ScheduleSchema } from '@soniq/schema-scheduling'

export const groupSchedulesByDay = groupBy<ScheduleSchema>(
  ({ event_start }) => {
    return new Date(event_start).getDay().toString()
  }
)

export const pickSchedulesOnDays = (
  weekDays: WeekDays[],
  schedules: ScheduleSchema[]
) => {
  const byDay = groupSchedulesByDay(schedules)
  return Object.keys(pick(weekDays.map(String), byDay)).map(
    (day) => ({
      day,
      schedules: byDay[day] as ScheduleSchema[],
    })
  )
}

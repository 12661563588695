import {
  GENERIC_SCHEDULE_EVENT_TYPE,
  LESSON_SCHEDULE_EVENT_TYPE,
} from '@soniq/schema-scheduling'

export const SCHEDULE_PATH = 'schedule'
export const VISIBLE_SCHEDULE_TYPES = [
  LESSON_SCHEDULE_EVENT_TYPE,
  GENERIC_SCHEDULE_EVENT_TYPE,
]

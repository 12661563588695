import { SupabaseClient } from '@pogokid/supabase'
import { SingleResponse } from '@soniq/public-resource'
import { SupabaseReadWriteStore } from '@soniq/public-resource/supabase'
import {
  ProfileSchema,
  ProfileWithId,
  validateProfile,
} from '@soniq/schema-connection'
import { Database } from '@soniq/util-supabase'
import { contactStore } from '../contact'

export class ProfileStore extends SupabaseReadWriteStore<
  'profile',
  ProfileSchema
> {
  static new() {
    return new ProfileStore('profile', validateProfile)
  }
}

const profileCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('profile')

export async function profileByUser(
  db: SupabaseClient,
  userId: string
): Promise<SingleResponse<ProfileWithId>> {
  const { data, error } = await profileCollection(db)
    .select()
    .eq('user_id', userId)
    .eq('profile_type', 'user')
    .maybeSingle<ProfileWithId>()
  if (error) throw error
  return { data }
}

export async function profileByHandle(
  db: SupabaseClient,
  handle: string
): Promise<SingleResponse<ProfileWithId>> {
  const { data, error } = await profileCollection(db)
    .select()
    .eq('handle', handle)
    .maybeSingle()
  if (error) throw error
  return { data: data as ProfileWithId }
}

export type ProfileWithDetails = ProfileWithId & {
  name?: string
  email?: string
}

export async function profileDetailsById(
  db: SupabaseClient,
  id: string
): Promise<SingleResponse<ProfileWithDetails>> {
  const { data: profile, error } = await profileCollection(db)
    .select()
    .eq('id', id)
    .maybeSingle()
  if (error) throw error
  if (profile?.profile_type === 'org' && profile.org_id) {
    return {
      data: {
        ...profile,
        handle: profile.handle || undefined,
        name: profile.org_id,
      },
    }
  } else if (
    profile?.profile_type === 'user' &&
    profile.user_id
  ) {
    const { data: contact } = await contactStore.fetchById(
      db,
      profile.user_id
    )
    return {
      data: {
        ...profile,
        handle: profile.handle || undefined,
        name: contact?.name,
        email: contact?.email || undefined,
      },
    }
  } else {
    return { data: null }
  }
}
